@import '../../../commonStyles.scss';

.darkTheme .ant-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.darkTheme .ant-input {
    background-color: #262a30 ;
    border: 1px solid #525252;
    color: #ffffff;
}

.ant-input-affix-wrapper {
    background-color: #262a30;
}

.ant-input:hover {
    border-color: #006b8c !important;
    outline: #006b8c !important;
}