@import '../../../commonStyles.scss';

.ant-btn {
    box-shadow: none;
    &.ant-btn-default {
        &:not(:disabled):hover,
        &:not(:disabled):active {
            color: $light !important;
            border-color: $neutral-1000;
            background: $neutral-1000;
        }

        &:focus {
            border-color: $neutral-1000;
            outline: none;
        }

        div {
            box-shadow: none;
        }
    }
}
