@import '../../../commonStyles.scss';

.fcpDropdownContainer {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    flex-wrap: wrap;
    @include mobileDevices {
        display: flex;
        flex-direction: column;
    }
}

.item {
    margin-right: 10px;
    width: 260px;

    @include mobileDevices {
        width: 100%;
        margin-bottom: 4px;
    }
}

.clearBtn {
    margin-top: 24px !important;
    @include mobileDevices {
        width: 60px;
        margin-right: auto;
        text-align: center;
        margin-top: 4px !important;
    }
    @media only screen and (min-width: 600px) and (max-width: 622px) {
        margin-top: 16px;
    }
}

.clearBtn button:focus {
    border: none;
    outline: none;
}

.fcpDropdownFacilityLabel,
.fcpDropdownCellLabel,
.fcpDropdownPartNumberLabel {
    font-size: 16px;
    color: rgb(241, 240, 240);
    text-align: left;
    align-self: end;
    margin-bottom: 4px;

    @include mobileDevices {
        margin-bottom: 6px;
    }
}

.fcpDropdownFacilitySelect {
    width: 100%;

    @include mobileDevices {
        margin-bottom: 4px;
    }
}

.fcpDropdownCellSelect {
    width: 100%;

    @include mobileDevices {
        margin-bottom: 4px;
    }
}

.fcpDropdownPartNumberSelect {
    width: 100%;

    @include mobileDevices {
        margin-bottom: 4px;
    }
}

.fcpDropdownClearBtn {
    margin-right: auto;
    background-color: #141414;
    color: #fff;
    border: none;
    outline: none;
    height: 34px;
}

.fcpDropdownClearBtn:hover {
    margin-right: auto;
    background-color: #141414 !important;
    color: #fff;
}

.fcpDropdownInfoIcon {
    margin-left: auto;
    margin-top: 4px;
    @include smallDesktop {
        position: absolute;
        top: 69px;
        right: 24px;
    }
    @include mobileDevices {
        position: absolute;
        top: 69px;
        right: 24px;
    }

    @include smallMobileDevices {
        position: absolute;
        top: 69px;
        right: 24px;
    }
}

@include mobileDevices {
    .fcpDropdownFacilityLabel {
        margin-right: auto;
    }

    .fcpDropdownCellLabel {
        margin-top: 6px;
        margin-right: auto;
    }

    .fcpDropdownPartNumberLabel {
        margin-right: auto;
        margin-top: 6px;
    }

    .fcpDropdownClearBtn {
        margin-top: 6px;
        margin-bottom: 2px;
    }

    .dpkTableResultsContainer {
        overflow: auto;
        width: 100%;
        min-width: 1px !important;
        max-height: 192px;
        @media screen and (width: 414px) {
            max-height: 400px;
            overflow: auto;
        }
        @media screen and (width: 390px) {
            max-height: 380px;
            overflow: auto;
        }
        @media screen and (width: 393px) {
            max-height: 380px;
            overflow: auto;
        }
        @media screen and (width: 360px) {
            max-height: 280px;
            overflow: auto;
        }
        @media screen and (width: 412px) {
            max-height: 450px;
            overflow: auto;
        }
    }
}

.ant-select {
    &.ant-select-open {
        .ant-select-arrow {
            top: 58% !important;
        }
    }
}
