@import '../../../commonStyles.scss';

.fcDropdownContainer {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    margin-bottom: 4px;
    flex-wrap: wrap;
    @include smallDesktop {
        display: flex;
        flex-direction: column;
    }
}

.facilitySelect,
.cellSelect {
    width: 100%;
    @include smallDesktop {
        margin-bottom: 5px;
    }
}

.cellLabel,
.facilityLabel {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: 6px;
    @include smallDesktop {
        margin-bottom: 8px;
    }
}

.fcItem {
    margin-right: 10px !important;
    width: 160px !important;

    @include smallDesktop {
        width: 100% !important;
        margin-bottom: 4px;
    }
}

.clearBtnFc {
    background-color: #141414;
    margin-top: 27px;
    @include smallDesktop {
        margin-right: auto;
        text-align: center;
        margin-top: 8px;
    }
    @media only screen and (min-width: 600px) and (max-width: 622px) {
        margin-top: 16px;
    }
}

.toolbar-info {
    margin-left: auto;
}

.helpIcon {
    width: 20px;
    cursor: pointer;
}

.infoIcon {
    margin-left: auto;
    margin-top: 4px;

    @include smallDesktop {
        position: absolute;
        top: 67px;
        right: 24px;
    }
    @include mobileDevices {
        position: absolute;
        top: 67px;
        right: 24px;
    }
    @include smallMobileDevices {
        position: absolute;
        top: 67px;
        right: 24px;
    }
}
