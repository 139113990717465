@import "../../../commonStyles.scss";

.startDateDurationRangeFilter {
    height: 50px;
    background-color: #1c1f23;
    padding: 25px;
    float: right;
    margin: 10px;

    @include tablet820 {
        float: unset;
        margin: 0px;
    }

    .startDateDurationContainerBox {
        position: relative;

        bottom: 150px;
        right: -10px;
    }

    .startDateDurationContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .startDateDurationItem {
            position: absolute;
            right: 20px;
            top: 120px;
            z-index: 1;

            .durationLabel {
                height: 25px;
            }

            button {
                background-color: #006B8C;
            }

            .durationSelect {
                top: 0;
                width: 160px;

            }

            &.start {
                right: 188px;
                width: 160px;
            }

            &.begin {
                right: 360px;
            }
        }


        .durationLabel {
            font-size: 16px;
            color: rgba(255, 255, 255, 0.87);
            margin-bottom: 6px;

        }
    }
}