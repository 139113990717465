.footerContent {
    text-align: center;
    height: 48px;
    background-color: #dae2e5;
    padding: 8px;
    box-sizing: border-box;
    float: left;
    width: 100%;
    display: flex;
    font-size: 14px;
}

.darkTheme .footerContent {
    background-color: #212529 !important;
    color: #fff;
}

.darkTheme .mobileCollapseLogo {
    padding: 10px;
    color: #fff;
    margin-right: 10px;

    width: 50px;

    height: 40px;
    color: #fff;
    background: #41484d;
    cursor: pointer;
    transition: all 0.2s;

    @media screen and (width: 280px) {
        height: 28px !important;
        height: 44px !important;
    }
}

.darkTheme .footerContent .footerText {
    flex-grow: 1;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (width: 393px) {
        flex-grow: 1;
        font-size: 13px !important;
    }
}

@media only screen and (min-width: 820px) {
    .darkTheme .footerContent .footerText {
        flex-grow: 1;
    }
}

.darkTheme .footerContent .footerExpandCollapse {
    align-items: center;
}
