.splashWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 193px);
    overflow: hidden;
}

.HomeLoader {
    margin-top: 150px !important;
    margin-left: -120px !important;
}

.splashWrapper .ant-spin-spinning {
    display: inline-block;
    opacity: 1;
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
}

.fadesplash {
    animation: fadeIn ease 3s infinite;
    -webkit-animation: fadeIn ease 3s infinite;
    -moz-animation: fadeIn ease 3s infinite;
    -o-animation: fadeIn ease 3s infinite;
    -ms-animation: fadeIn ease 3s infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
