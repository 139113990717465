@import "./commonStyles.scss";

.welcomeCard {
    float: left;
    width: 100%;
    background-color: #fafafa;
}

.welcomeText {
    color: #000;
    font-size: 3.9rem;
    text-align: center;
    font-weight: 600;
}

.blueText {
    color: #006b8c;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
}

.cardBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #e9edf0;
    min-height: calc(100vh - 285px);
    border-radius: 4px;
    margin-top: 100px;
}

.description {
    font-size: 1.4rem;
    width: 55%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.livelineLogo {
    max-width: 180px;
    margin-top: 10px;
}

.leftLogo {
    padding: 15px 0 20px 22px;
    float: left;
    box-sizing: border-box;
    width: 17%;
}

.footer {
    background-color: #f0f0f0;
    text-align: center;
    color: #666;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    float: left;
    width: 100%;
    margin-top: 120px;
}

html {
    background-color: #222;
    font-size: 13px;
}

.darkTheme .alignConfigurationSelect {
    display: flex;
    flex-direction: row;
    padding: 2px;
    flex-wrap: wrap;
    padding-top: 10px;

    @include mobileDevices {
        display: block;
        padding-right: 10px;
    }

    padding-left: 12px;
}

.addcustomerLabel {
    font-size: 16px;
    color: rgb(241, 240, 240) !important;
    text-align: left;
    align-self: end;
    margin-bottom: 4px;
    display: block;
}

.clearBtn {
    margin-top: 30px;

    @include mobileDevices {
        display: block;
        padding-right: 10px;
    }
    padding-left: 12px;

    .addcustomerLabel {
        font-size: 16px;
        color: rgb(241, 240, 240) !important;
        text-align: left;
        align-self: end;
        margin-bottom: 4px;
        display: block;
    }

    .clearBtn {
        margin-top: 30px;

        @include mobileDevices {
            margin-top: 0;
        }
    }

    .formControl {
        @include mobileDevices {
            margin-bottom: 10px;
        }
    }
}

.customerSelect {
    width: 200px;
    margin-right: 10px;

    @include mobileDevices {
        width: 100%;
    }
}

.facilitySelect {
    width: 200px;

    @include mobileDevices {
        width: 100%;
    }
}

.ant-spin-text {
    color: #f0f0f0 !important;
}

.ant-spin-dot-item {
    background-color: #f0f0f0 !important;
}

// Liveline dashboard TV typographies
.tv-h1-semi-bold {
    font-size: 5.5385rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.tv-h2-bold {
    font-size: 2.7692rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4.0242rem;
}

.tv-h3-bold {
    font-size: 2.1538rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.4981rem;
}

.tv-h4-bold {
    font-size: 1.6923rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.1538rem;
}

.tv-h4-medium {
    font-size: 1.6923rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2994rem;
}

.tv-h5-semi-bold {
    font-size: 1.3846rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.1538rem;
}

.tv-h5-bold {
    font-size: 1.3846rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.1538rem;
}

.tv-body-semi-bold {
    font-size: 1.0769rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.6923rem;
}

.tv-body-medium {
    font-size: 1.0769rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.6923rem;
}

.tv-footnote-bold {
    font-size: 0.9231rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8462rem;
}

.tv-icon {
    font-size: 1.8462rem;
}

// tablet view typographies for the operator console
.tablet-h1-semi-Bold {
    font-size: 15.077rem;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
}

.tablet-h2-bold {
    font-size: 7.385rem;
    font-weight: 700;
    line-height: 4.025rem;
    font-style: normal;
}

.tablet-h3-bold {
    font-size: 4.923rem;
    font-weight: 500;
    line-height: 5.769rem;
    font-style: normal;
}

.tablet-h4-bold {
    font-size: 3.69231rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.15385rem;
    /* 58.333% */
}

.tablet-h5-bold {
    font-size: 2.76923rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.tablet-body-semi-bold {
    font-size: 1.84615rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 2.58462rem */
}

.tablet-body-bold {
    font-size: 1.84615rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 2.58462rem */
}

.tablet-body-bold {
    font-size: 1.84615rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 2.58462rem */
}

.tablet-footnote-bold {
    font-size: 1.69231rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.29938rem;
    /* 76.782% */
}

// Liveline dashboard OEE Console typographies

.dashboard-h1-medium {
    font-size: 2.9231rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.5385rem; /* 121.053% */
}

.dashboard-h2-medium {
    font-size: 2.3077rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.0769rem; /* 133.333% */
}

.dashboard-h3-medium {
    font-size: 1.8462rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.4615rem; /* 133.333% */
}

.dashboard-h3-regular {
    font-size: 1.8462rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4615rem; /* 133.333% */
}

.dashboard-h4-medium {
    font-size: 1.5385rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.1538rem; /* 140% */
}

.dashboard-h5-regular {
    font-size: 1.2308rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8462rem; /* 150% */
}

.dashboard-h5-medium {
    font-size: 1.2308rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.8462rem; /* 150% */
}

.dashboard-h5-bold {
    font-size: 1.2308rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.8462rem; /* 150% */
}

.dashboard-body-regular {
    font-size: 1.0769rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6923rem; /* 157.143% */
}

.dashboard-body-medium {
    font-size: 1.0769rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.6923rem; /* 157.143% */
}

.dashboard-body-bold {
    font-size: 1.0769rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.6923rem; /* 157.143% */
}

.dashboard-body-regular-underline {
    font-size: 1.0769rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6923rem; /* 157.143% */
    text-decoration-line: underline;
}

.dashboard-body-regular-strikethrough {
    font-size: 1.0769rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6923rem; /* 157.143% */
    text-decoration-line: strikethrough;
}

.dashboard-body-code {
    font-family: Menlo;
    font-size: 1.0769rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6923rem; /* 157.143% */
}

.dashboard-footnote-description {
    font-size: 0.9231rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5385rem; /* 166.667% */
}

.dashboard-footnote-system-monospace {
    font-family: "Roboto Mono";
    font-size: 0.9231rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5385rem; /* 166.667% */
}

.model-metrics-body-regular {
    font-size: 1.0769rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6923rem; /* 157.143% */
}

.color-black {
    color: $black;
}

.color-white {
    color: $white;
}

.color-light {
    color: $light;
}

.color-white-80-percent {
    color: $white-80-percent;
}

.color-extra-dark-blue {
    color: $livewire-blue-extra-dark;
}

.bg-color-operator-green {
    background-color: $operator-console-green;
}

.bg-color-operator-red {
    background-color: $operator-console-red;
}

.bg-color-operator-flash1 {
    background-color: #cd7b73;
}

.bg-color-operator-flash2 {
    background-color: #db9f99;
}

.bg-color-operator-grey {
    background-color: #bec0c1;
}

.color-oee-red {
    background-color: $detail-panel-red !important;
}

.color-oee-green {
    background-color: $operator-console-green;
}

.display-flex-row {
    display: flex;
    flex-direction: row;
}

.display-flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.display-flex-column {
    display: flex;
    flex-direction: column;
}

.display-flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
}
