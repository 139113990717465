.ant-input-number:hover {
    border-color: #006b8c !important;
    outline: #006b8c !important;
}

.ant-input-number {
    .ant-input-number-handler-down {
        border-block-start: 1px solid #424242;
        border-end-end-radius: 0;
    }
    .ant-input-number-handler-up {
        border-start-end-radius: 0;
    }
}

.ant-input-number-input-wrap{
    background-color: #262a30;
}