@import '../../commonStyles.scss';

.headerContainer {
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .headerTitle {
        margin-top: 4px;
    }
}

.headerUserProfile {
    display: flex;

    @media screen and (width: 280px) {
        font-size: 10px;
    }
    @include mobileDevices {
        font-size: 12px;
    }
}

.currentPage {
    text-transform: capitalize;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    @media screen and (width: 280px) {
        text-transform: capitalize;
        font-size: 10px;
        font-weight: 600;
        margin-bottom: 3px !important;
    }
    @include mobileDevices {
        font-size: 12px;
    }
}
.userName {
    margin-left: auto;
    &.profileUserName {
        padding: 4px 10px !important;
    }
}
.userIcon {
    margin-right: 10px;
    display: flex;
    align-self: center;
}
.profileMenuItem {
    display: flex;
    cursor: pointer;
    padding-bottom: 5px;
    padding-top: 5px;
}

.menuIcon {
    padding-right: 8px;
    height: 18px;
}

.menuName {
    font-size: 14px;
}

.userImg {
    float: left;
    border: 1px solid #32383e;
    background: #32383e;
    border-radius: 50%;
    padding: 3px;
    height: 28px;
    cursor: pointer;
    @media screen and (width: 280px) {
        margin-right: 3px;
        height: 20px;
    }
}

.productIcon {
    @media screen and (width: 280px) {
        margin-right: 3px;
        height: 20px;
    }
}
.currentNav {
    margin-top: 10px;
}

.darkTheme .collapseIcon {
    margin-right: 4px;
    width: 25px;
    height: 20px;
    margin-top: -8px;
    cursor: pointer;
}

.moduleIcon {
    margin-left: 1rem;
    width: 25px;
    height: 25px;
    margin-top: -8px;
    cursor: pointer;
}

.productIcon {
    display: inline-block;
    margin-right: 1rem;
}
.productIcon.static {
    margin-top: 0px;
    height: 20px;
    width: 20px;
    background-image: url('../../assets/dark-theme/icons/product-suite-20px.png');
}
.productIcon.static.basic {
    background-position: 0px 0px;
}
.productIcon.static.controls {
    background-position: -20px 0px;
}
.productIcon.static.alarms {
    background-position: -40px 0px;
}
.productIcon.static.reporting {
    background-position: -60px 0px;
}

.productIcon.svg {
    height: 25px;
    width: 25px;
    background-size: 25px;
}

.productIcon.svg.basic {
    background-image: url('../../assets/dark-theme/icons/module-basic-platform.svg');
}

.productIcon.svg.controls {
    background-image: url('../../assets/dark-theme/icons/module-controls.svg');
}

.productIcon.svg.alarms {
    background-image: url('../../assets/dark-theme/icons/module-alarm.svg');
}

.productIcon.svg.reporting {
    background-image: url('../../assets/dark-theme/icons/module-reporting.svg');
}
