.ant-picker:hover,
.ant-picker-focused {
    border-color: #006b8c !important;
    outline: #006b8c !important;
}

.ant-picker {
    background-color: #282c30;
    .ant-picker-input > input::placeholder {
        color: #929497 !important;
    }
    .ant-picker-clear {
        background-color: #282c30;
        display: inline-flex;
        align-items: center;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}
