.tabMenuList {
 
  .ant-menu-submenu {
    background: rgba(62, 64, 69, 0.44);
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    margin-right: 5px;
    
    &.ant-menu-submenu-open {
      background-color: #1b1d21 !important;
    }
    &.ant-menu-submenu-selected {
      background-color: #1b1d21;
      border-bottom: 1px solid #fff;
      color: #fff !important;

      &::after {
        display: none;
        width: 100%;
        left: 0;
      }
      &:hover {
        border-bottom-width: 2px;
        border-bottom-color: #fff;
        &::after {
          display: none;
          width: 100%;
          left: 0;
        }
      }
    }

    &:hover {
      border-bottom-width: 2px;
      border-bottom-color: #fff;
    }
    &::after {
      display: none;
    }
    .ant-menu-submenu-title {
       background-color: unset !important;
      &:hover {
        color: #fff !important;
      }
      &:active {
        background-color: inherit !important;
      }
    }
  }
}
.ant-menu-submenu-placement-bottomLeft {
  margin-top: 8px !important;
  padding-top:5px !important ;
}
 .ant-menu-submenu-open {
  border-bottom: none !important;
}