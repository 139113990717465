@import '../../../commonStyles.scss';
.ant-select-dropdown {
    background-color: $Dark-100 !important;
    color: $light !important;

    .ant-select-item {
        color: $light !important;
    }

    .ant-empty-description {
        color: $light !important;
    }
}

.ant-select {
    .ant-select-selection-placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
    }
}

.ant-select-single.ant-select-open .ant-select-selection-item {
    color: rgba(255, 255, 255, 0.5) !important;
}

// COMMENTED OUT BY REWII
// .darkTheme .anticon svg {
//     display: inline-block;
//     color: grey;
// }

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #13121266 !important;
}

.ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
    .ant-select-selector {
    border-color: #dc4446 !important;
}

.ant-select-single .ant-select-selector {
    border-radius: 4px;
}

.ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
    .ant-select-selector {
    border-color: #dc4446;
}

.darkTheme {
    .ant-select {
        .ant-select-selector {
            box-shadow: none !important;
        }
    }
}

.ant-empty-image svg path {
    fill: #222428 !important;
}

.ant-empty-image svg ellipse {
    fill: #222428 !important;
}

.ant-empty-image svg g {
    stroke: #5f6468 !important;
}

// THIS IS A DUPLICATE ON LINE #25 REWII
// .darkTheme .anticon svg {
//     display: inline-block;
//     color: grey;
// }



.ant-select {
    .ant-select-clear {
        position: absolute;
        top: 50%;
        inset-inline-start: auto;
        inset-inline-end: 11px;
        z-index: 1;
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-top: -6px;
        color: rgba(255, 255, 255, 0.25);
        font-size: 12px;
        font-style: normal;
        line-height: 1;
        text-align: center;
        text-transform: none;
        background: #262a30;
        cursor: pointer;
        opacity: 0;
        transition: color 0.2s ease, opacity 0.3s ease;
        text-rendering: auto;
        &:hover{
            color: rgba(255, 255, 255, 0.25);
        }
    }
   
        .ant-select-arrow {
            color: rgba(255, 255, 255, 0.25);
           
        }
    
}