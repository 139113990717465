.ant-btn.ant-btn-lg {
    height: 33px;
}

.ant-btn.ant-btn-lg.ant-btn-icon-only {
    font-size: 13px;
}

.darkTheme .ant-select-selector {
    background-color: #262a30 !important;
    border: 1px solid #525252 !important;
    color: #ffffff;
}

.darkTheme .searchBox.ant-input-search-large .ant-input-affix-wrapper {
    border: 1px solid #525252;
    border-right: 0;
    background-color: #262a30;
    color: #fff;
}

.darkTheme .ant-input-search-button {
    border: 0;
    padding: 0;
    width: 30px;
    min-width: 30px;
    background-color: #32383e;
}

.darkTheme .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    inset-inline-start: -1px;
    padding: 0;
    border: 1px solid #525252;
    border-left: 0;
    color: #fff;
}

.ant-btn.ant-btn-lg.ant-btn-icon-only .anticon {
    font-size: 14px;
    margin-top: 5px;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    border: 1px solid #525252;
    outline: none !important;
    box-shadow: none !important;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-sm{
    padding: 4px !important;
    padding-left: 11px !important;
}