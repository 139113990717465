.ant-notification-notice.NotificationLLPopover.SuccessNotification.ant-notification-notice-closable {
    opacity: 1;
    border: 1px solid #1f1f1f;
    color: #1c4426;
    background-color: #1f1f1f;
    padding: 15px;
    border-radius: 4px;

    .ant-notification-notice-close-x {
        color: #04802b;
    }
}

.ant-notification-notice.NotificationLLPopover.FailedNotification.ant-notification-notice-closable {
    opacity: 1;
    border: 1px solid #1f1f1f;
    color: #ea3939;
    background-color: #1f1f1f;
    padding: 15px;
    border-radius: 4px;

    .ant-notification-notice-close-x {
        color: #ea3939;
    }
}

.ant-notification-notice.NotificationLLPopover.WarningNotification.ant-notification-notice-closable {
    opacity: 1;
    border: 1px solid #1f1f1f;
    color: #a58806;
    background-color: #1f1f1f;
    padding: 15px;
    border-radius: 4px;

    .ant-notification-notice-close-x {
        color: #a58806;
    }
}

.ant-notification-notice.NotificationLLPopover.InformationNotification.ant-notification-notice-closable {
    opacity: 1;
    border: 1px solid #1f1f1f;
    color: #006b8c;
    background-color: #1f1f1f;
    padding: 15px;
    border-radius: 4px;

    .ant-notification-notice-close-x {
        color: #006b8c;
    }
}

.ant-notification-notice-with-icon {
    .ant-notification-notice-description {
        margin-left: 48px !important;
        font-size: 1rem;
        color: #fff !important;
    }

    .ant-notification-notice-message {
        margin-bottom: 0 !important;
        margin-left: 48px !important;
        font-size: 1.1rem;
        font-weight: 500;
        color: #fff !important;
    }
}

.ant-notification-notice-close {
    position: absolute;
    top: 10px !important;
    right: 15px !important;
    color: rgb(161, 161, 161) !important;
    outline: none;
}
