@import '../../commonStyles.scss';

.darkTheme {
    background: url('../../assets/dark-theme/icons/login-background.png') !important;
    background-repeat: no-repeat;
    background-position: 50% 65% !important;
    background-attachment: fixed !important;
    background-size: cover !important; 
    .site-layout {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        .logoView {
            margin-bottom: 28px;
            @include mobileDevices {
                width: unset;
            }
            .logoIMG {
                height: 125px;
            }
        }
    }
}
.darkTheme .content {
    background-color: #212529 !important;
    margin: 20px 16px 10px;
}
.darkTheme .alignLeftNav {
    background-color: #212529 !important;
}

.darkTheme .ant-menu {
    background-color: #212529 !important;
    color: #fff;
}

.darkTheme .ant-layout-sider-children {
    background-color: #212529 !important;
    color: #fff;
}

.darkTheme .headerContainer {
    background-color: #292e32 !important;
    color: #fff;
}

.darkTheme .ant-layout {
    background-color: #1b1d21;
}

.darkTheme .middleContent .customersText {
    color: #fff !important;
}

.darkTheme .ant-pagination-item-link {
    border-color: #32383e !important;
}

.darkTheme .anticon-left {
    color: #fff;
    font-size: 15px;
}

.darkTheme .anticon-right {
    color: #fff;
    font-size: 15px;
}

.darkTheme .addcustomerLabel {
    color: #c3c3c3 !important;
}

.darkTheme .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    inset-inline-start: -1px;
    padding: 0;
    border: 1px solid #525252;
    border-left: 0;
    color: #fff;
    border-radius: 1px;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #006b8c;
    background-color: #006b8c;
}

body.no-scroll {
    overflow: hidden;
}

@media only screen and (max-width: 1000px) {
    .darkTheme .headerContainer p {
        margin-left: 40px;
    }
}

@media only screen and (min-width: 820px) {
    .darkTheme .mbtn {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    .darkTheme .mbtn {
        display: inline;
    }

    .darkTheme .alignLeftNavCollapse .ant-layout-sider-trigger {
        display: none;
    }
}

@media (min-width: 1001px) and (max-width: 1023px) {
    .darkTheme .mbtn {
        display: inline;
    }

    .darkTheme .alignLeftNavCollapse .ant-layout-sider-trigger {
        display: none;
    }
}

.darkTheme .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    inset-inline-start: -1px;
    padding: 0;
    border: 1px solid #525252;
    border-left: 0;
    color: #fff;
    border-radius: 3px;
}

@media screen and (min-wdth: 280px) {
    .darkTheme .ant-layout-content {
        min-height: calc(100vh - 144px);
        max-height: calc(100vh - 144px);
        overflow: auto;
    }
}
@media only screen and (min-width: 280px) {
    .darkTheme .ant-layout-content .lineTemplate {
        @include mobileDevices {
            background-color: #111317 !important;
        }
        @include tablet {
            background-color: #111317 !important;
        }
        @include tablet820 {
            background-color: #111317 !important;
        }
    }
    .darkTheme .footerText {
        margin-top: 16px;
        margin-bottom: 22px;
        font-size: 11px;
        justify-content: center;
        display: flex;
        align-items: center;
    }
    .darkTheme .footerExpandCollapse {
        margin-left: -3px;
        margin-top: 5px;
        margin-bottom: 18px;
    }

    .darkTheme .footerContent {
        padding: 5px;
        height: 60px;
        display: flex;
    }
}
.darkTheme .ant-layout-content {
    min-height: calc(100vh - 144px);
    max-height: calc(100vh - 144px);
    overflow: auto;
}
@media only screen and (max-width: 1024px) {
    .darkTheme .logo {
        width: 110px;
    }
}

@media (max-width: 280px) {
    .darkTheme .footerContent {
        display: flex;
    }
    .darkTheme .footerText {
        margin-top: 16px;
        margin-bottom: 22px;
        font-size: 11px;
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

.resetBtn {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.equipmentContent {
    // &::-webkit-scrollbar - CSS pseudo-element that targets the scrollbar in webkit-based browsers.
    // Apply styles to the scrollbar and its track, thumb, and buttons.
    &::-webkit-scrollbar {
        background-color: #eee;
    }

    &::-webkit-scrollbar-thumb {
        background: #d3d3d3;
    }
}
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #414141;
    border-radius: 5px;
}
input:-webkit-autofill {
    -webkit-text-fill-color: #e5e5e5;
    -webkit-box-shadow: 0 0 0px 1000px rgba(53, 55, 59, 0.4) inset;
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill:focus {
    -webkit-text-fill-color: #e5e5e5;
    -webkit-box-shadow: 0 0 0px 1000px rgba(53, 55, 59, 0.4) inset;
    transition: background-color 5000s ease-in-out 0s;
}

.login-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    align-self: center;
    width: 383px;
    height: auto;
    background-color: #ffffff05;
    border: 1px solid #70707080;
    padding: 15px;
    box-shadow: none !important;
    @include smallMobileDevices {
        width: auto;
    }
    &:hover {
        box-shadow: none !important;
        border: 1px solid #70707080;
    }
    .alertText {
        background: #fff;
        border: 1px solid red;
        color: red;
        margin-bottom: 10px !important;
        padding: 10px !important;
    }
    .ant-card-head {
        border-bottom: 0;
        .ant-card-head-title {
            color: #ffffff;
            font-size: 24px;
            font-family: 'Segoe UI', Bold;
            text-align: center;
        }
    }
    .fieldView {
        margin-bottom: 10px;
    }
    .labelView {
        font-family: 16px !important;
        color: #ffffff;
        font-family: 'Segoe UI', Regular;
        &.emaillable {
            padding-bottom: 5px;
            float: left;
        }
    }
    .ant-input-affix-wrapper {
        border: 1px solid #64656d !important;
        background-color: #35373b66 !important;
        padding: 0 0 !important;
        margin-top: 5px;
        .ant-input {
            background-color: #35373b66 !important;
            border: 0 !important;
            padding-left: 10px;
        }
    }
    
    .ant-input-suffix {
        display: block;
        padding: 6px;
        background-color: #2b3338;
        border-radius: 5px;
    }
    .ant-input-affix-wrapper {
        .ant-input-suffix {
            margin-inline-start: 0;
        }
        .anticon.ant-input-password-icon {
            color: rgb(100 101 109);
            cursor: pointer;
            transition: all 0.3s;
        }
        &:hover {
            color: rgb(100 101 109);
        }
    }

    .forgetPassword {
        color: #eaae11;
        &:hover {
            color: #eaae11 !important;
        }
    }
    .ant-input {
        border: 1px solid #64656d !important;
        background-color: #35373b66 !important;
        height: 35px !important;
    }

    .signBtnView {
        margin-top: 10px;
        text-align: center;
    }
    .SignBtn {
        color: #1b1d21;
        background-color: #eaae11;
        outline: none !important;
        text-align: center;
        width: 150px;
        &:hover {
            color: #1b1d21 !important;
            background-color: #eaae11 !important;
            outline: none !important;
            text-align: center !important;
            width: 150px !important;
        }
    }
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #6e6e6e;
}

::-webkit-scrollbar-corner {
    background: 0 0;
}

body::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.anticon-search {
    margin-bottom: 4px;
}
.ant-table-wrapper.ant-table-column-sorter-up.active svg,
.ant-table-wrapper .ant-table-column-sorter-down.active svg {
    color: #1668dc !important;
}

.ant-table-wrapper.ant-table-column-sorter-up.active svg,
.ant-table-wrapper .ant-table-column-sorter-up.active svg {
    color: #1668dc !important;
}

.darkTheme .ant-menu-item {
    padding-left: 16px !important;
    height: 45px;
}

.darkTheme .ant-menu-submenu-title {
    padding: 0px 14px !important;
}

.darkTheme .ant-menu-sub {
    padding-left: 20px !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 22px;
    border: none;
}

.ant-switch.ant-switch-small.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 14px);
    //opacity: 0.3;
}

.ant-switch.ant-switch-small .ant-switch-handle {
    width: 12px;
    height: 12px;
    //opacity: 0.3;
}

.ant-steps-horizontal {
    margin-top: 15px;
    margin-bottom: 15px;
}
