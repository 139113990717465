$primary-100: #006b8c; //Theme
$primary-200: #074f72;
$primary-300: #00539f; //Landing Tile Border
$primary-400: #92b1c0; //LightBackground
$primary-500: #1c8abf;

$light: #fff;
$neutral: #e9edf0;
$neutral-100: #fcfcfc;
$neutral-200: #f8f8f8;
$neutral-300: #f0f0f0;
$neutral-400: #d5d5d5; //border
$neutral-500: #e6e6e6;
$neutral-600: #bfbfbf;
$neutral-700: #a3a3a3;
$neutral-800: #8f969a;
$neutral-900: #666;
$neutral-1000: #5a5a5a;
$neutral-1001: #707070;
$neutral-1002: #f8f9fa;
$neutral-1003: #e2e2e2;
$neutral-1004: #f7f7f7;
$neutral-1005: #888a99;
$neutral-1006: #1a1d20;

$ternary: #e7d6ac;

$Dark: #222;
$Dark-100: #262a30;
$InProgress: #f00;
$Completed: #2cb90b;
$Delete: #dc584d;
$yellow: #eeb211;
$editIcon: #006b8c;
$sorterIcon: #1668dc;

$Typo-SemiBold: 600;
$Typo-Bold: bold;
$Typo-Regular: 400;
$Typo-medium: 500;
$rts-1: 0.9rem; //18px;
$rts-2: 1rem; //20px;
$rts-3: 1.2rem; //24px;
$rts-4: 1.4rem; //28px;
$rts-5: 1.8rem; //36px;
$rts-6: 0.8rem; //16px
$heading-1: 3rem; //60px;
$heading-2: 3.9rem; //78px;

// Volcano
$volcano-1: #fff2e8;
$volcano-2: #ffd8bf;
$volcano-3: #ffbb96;
$volcano-4: #ff9c6e;
$volcano-5: #ff7a45;
$volcano-6: #d48d86;
$volcano-7: #d4380d;
$volcano-8: #ad2102;
$volcano-9: #871400;
$volcano-10: #610b00;

// Polar Green
$polar-green-1: #f6ffed;
$polar-green-2: #d9f7be;
$polar-green-3: #b7eb8f;
$polar-green-4: #95de64;
$polar-green-5: #73d13d;
$polar-green-6: #52c41a;
$polar-green-7: #389e0d;
$polar-green-8: #237804;
$polar-green-9: #135200;
$polar-green-10: #092b00;

// Calendula Gold
$calendula-gold-1: #fffbe6;
$calendula-gold-2: #fff1b8;
$calendula-gold-3: #ffe58f;
$calendula-gold-4: #ffd666;
$calendula-gold-5: #ffc53d;
$calendula-gold-6: #faad14;
$calendula-gold-7: #d48806;
$calendula-gold-8: #ad6800;
$calendula-gold-9: #874d00;
$calendula-gold-10: #613400;

// blue
$blue-1: #e6f7ff;
$blue-2: #bae7ff;
$blue-3: #91d5ff;
$blue-4: #69c0ff;
$blue-5: #40a9ff;
$blue-6: #1890ff;
$blue-7: #096dd9;
$blue-8: #0050b3;
$blue-9: #003a8c;
$blue-10: #002766;

// Liveline Dashboard

// Liveline suggested colors
$liveline-primary-background: #1b1d21;
$liveline-secondary-background: #212529;
$liveline-tertiary-background: #252c30;
$liveline-border: #2a2f35;

// primary color
$livewire-blue-extra-dark: #00171e;
$livewire-blue-extra-dark-80-percent: #00171ecc; // 80% opacity of $livewire-blue-extra-dark:
$livewire-blue-dark: #00405a;
$livewire-blue-med: #006b8c;
$livewire-blue-light: #7fb9c5;
// secondary color
$livewire-yellow: #fdbb3b;
$livewire-red: #c13d3d;
$livewire-green: #729a78;
$livewire-hyperlink: #2767c5;
$livewire-standard-blue: #0163ab;
$livewire-idle: #bfdce2;
$livewire-oee-overlay: rgba(0, 23, 30, 0.8);
// grey color
$livewire-grey-medium: #555859;
$livewire-grey-light: #bec0c1;
$livewire-grey-super-light: #dbe2e5;
// base color
$black: #000000;
$white: #ffffff;
$white-80-percent: #ffffffcc; // 80% opacity of $white

// operator console colors
$operator-console-green: #729a78;
$operator-console-red: #d48d86;

// custom-header
$header-height: 6.15385rem;

//detail panel color
$detail-panel-red: #d48d86;

// tablet-input-color
$tablet-input-color: #000000e0;

// model metrics
$model-metrics-rule-notes-bg: #2c2c2c;

// dark background colour
$dark-main-bg: #1b1d20;
$dark-secondary-bg: #212529;
$dark-tertiary-bg: #252c30;
$dark-table-border: #2a2f35;

@mixin LargeDesktop1900 {
    @media only screen and (min-width: 1900px) {
        @content;
    }
}

@mixin Desktop1536 {
    @media only screen and (max-width: 1536px) {
        @content;
    }
}

@mixin Desktop1280 {
    @media only screen and (max-width: 1280px) {
        @content;
    }
}

@mixin Desktop1200 {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}

@mixin LargeDesktop1500 {
    @media only screen and (min-width: 1500px) {
        @content;
    }
}

@mixin LargeDesktop1344 {
    @media only screen and (min-width: 1344px) {
        @content;
    }
}

@mixin Desktop1100 {
    @media only screen and (max-width: 1100px) {
        @content;
    }
}

@mixin smallDesktop {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin smallDesktop-landscape {
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
        @content;
    }
}

@mixin smallDesktop-portrait {
    @media only screen and (max-width: 1024px) and (orientation: portrait) {
        @content;
    }
}

@mixin Device1000 {
    @media only screen and (max-width: 1000px) {
        @content;
    }
}

@mixin Device1000-landscape {
    @media only screen and (max-width: 1000px) and (orientation: landscape) {
        @content;
    }
}

@mixin Device1000-portrait {
    @media only screen and (max-width: 1000px) and (orientation: portrait) {
        @content;
    }
}

@mixin tablet820 {
    @media only screen and (max-width: 820px) {
        @content;
    }
}

@mixin tablet820-landscape {
    @media only screen and (max-width: 820px) and (orientation: landscape) {
        @content;
    }
}

@mixin tablet820-portrait {
    @media only screen and (max-width: 820px) and (orientation: portrait) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: 768px) {
        @content;
    }
}

@mixin tablet-landscape {
    @media only screen and (max-width: 768px) and (orientation: landscape) {
        @content;
    }
}

@mixin tablet-portrait {
    @media only screen and (max-width: 768px) and (orientation: portrait) {
        @content;
    }
}

@mixin mobileDevices {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

@mixin smallMobileDevices {
    @media only screen and (max-width: 375px) {
        @content;
    }
}

@mixin Desktop1800 {
    @media only screen and (max-width: 1860px) {
        @content;
    }
}

@mixin Desktop1920 {
    @media only screen and (min-width: 1920px) {
        @content;
    }
}
