.darkTheme .ant-table-thead .ant-table-cell {
    background-color: #1b1d21 !important;
    color: #fff !important;
}

.darkTheme .ant-table-tbody {
    background-color: #212529 !important;
    color: #ced4da !important;
}

.darkTheme .ant-table-row .ant-table-cell-row-hover {
    background-color: #1b1d21 !important;
    color: #ced4da !important;
    cursor: pointer;
}

.darkTheme .ant-table {
    border-radius: 0 !important;
}

.darkTheme .ant-table-wrapper table {
    width: 100%;
    text-align: start;
    border-collapse: separate;
}

.ant-table-wrapper .ant-table {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    background: #2c2c2c;
    border-radius: 8px 8px 0 0;
    scrollbar-color: unset !important;
}

.darkTheme .ant-table-tbody > tr > td {
    border-bottom: 1px solid #2a2f35 !important ;
    border-top: 1px solid #2a2f35 !important  ;
    border-right: 1px solid #2a2f35 !important  ;
    border-left: 1px solid #2a2f35 !important  ;
}

.darkTheme .ant-table tr th {
    border-bottom: 1px solid #2a2f35 !important ;
    border-top: 1px solid #2a2f35 !important  ;
    border-right: 1px solid #2a2f35 !important  ;
    border-left: 1px solid #2a2f35 !important  ;
}

.ant-table-wrapper .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: none;
}

.ant-table-wrapper .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
    box-shadow: none;
}

.ant-pagination .ant-pagination-options {
    display: block !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options {
    margin-inline-start: 10px;
}

.ellipsis {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options {
    margin-inline-start: 10px;
}

.ellipsis {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options {
    margin-inline-start: 10px;
}

.ant-table-wrapper .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: none;
}

.ant-table-wrapper .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
    box-shadow: none;
}

.ant-pagination .ant-pagination-options {
    display: block !important;
}

.ant-table-wrapper.ant-table-column-sorter-up.activesvg,
.ant-table-wrapper .ant-table-column-sorter-down.active svg {
    color: #006b8c;
}

.ant-table-wrapper.ant-table-column-sorter-up.activesvg,
.ant-table-wrapper .ant-table-column-sorter-up.active svg {
    color: #006b8c;
}
.ant-table-wrapper .ant-table-expanded-row-fixed {
    position: relative;
    margin: -16px -16px;
    padding: 16px 16px;
    background-color: #212429 !important;
}

.ant-table-wrapper td.ant-table-column-sort {
    background: none;
}

.ant-table-wrapper.ant-table-column-sorter-up.activesvg,
.ant-table-wrapper .ant-table-column-sorter-down.active svg {
    color: #006b8c !important;
}

.ant-table-wrapper.ant-table-column-sorter-up.activesvg,
.ant-table-wrapper .ant-table-column-sorter-up.active svg {
    color: #006b8c !important;
}

.ant-table-expanded-row-fixed {
    background-color: #212429;
}

.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
    border-inline-start: 0;
    border-top: 0;
}

.ant-empty-normal .ant-empty-description {
    color: #5f6468;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: #212529;
}

.ant-pagination .ant-pagination-item-active a {
    color: #ffffff;
}

.ant-pagination .ant-pagination-item-active a:hover {
    color: #ffffff;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder {
     background: #212529  !important; 
     &:hover{
        background: #212529  !important; 
     }
}