.errorBoundary_liveline_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: url("../../../assets/dark-theme/icons/login-background.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.errorBoundary_livelineLogo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.errorBoundary_logoIMG {
  height: 44px;
}

.errorBoundary_homeBtn {
  margin: 10px;
  width: 120px;
  color: #000;
  background-color: #eeb211;
  &:hover {
    color: #000 !important;
    background-color: #eeb211 !important;
  }
}

.errorBoundary_contactBtn {
  margin: 10px;
  width: 120px;
  background-color: transparent;
  border-color: #595555;
  &:hover {
    background-color: transparent !important;
    border-color: #595555 ;
  }
}

.errorBoundary_msg {
  color: #fff;
  font-size: 25px;
  padding: 50px 0 0 0;
  display: flex;
  justify-content: center;
}

.errorBoundary_btnContainer {
  display: flex;
  justify-content: center;
}
